import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { graphql, Link, PageProps } from "gatsby";

import { Query, SanityBlogInnerConnection } from "@graphql-types";
import SEO from "@shared/seo";
import { Wrapper, FlexGrid, Container } from "@util/standard";
import { BlocksContent, SwipeIndicator, Button, ProviderContainer } from "@global";
import { useCheckScreenWidth, useEventListener, useHasMounted } from "@util/hooks";
import { HeroTemplate } from "@shared/hero";
import { useStore } from "@state/store";
import { colors, colorsRGB, TABLET_BREAKPOINT, SMALL_LAPTOP_BREAKPOINT } from "@util/constants";
import { setSlug, toPlainText } from "@util/helper";
import { useAllCategories, useBlogInnerLanguage, usePageSlugs } from "@query";
import {
  FullScreenContentBlock,
  FeaturedBlogsBlock,
  NewsletterBlock,
} from "@components";
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { LinkedInIcon, FacebookIcon, TwitterIcon } from "@util/svg";

interface Data extends Query {
  altRegions: SanityBlogInnerConnection;
}

interface Props extends PageProps {
  data: Data;
}

const HeroContent = styled(Container)`
  color: ${colors.white};
  h1{
    margin-top: 10px;
  }
  a{
    color: currentColor;
    text-decoration: none;
    &:hover{
      color: currentColor;
    }
  }
  svg{
    path{
      fill: currentColor;
    }
  }
`;

const BlogInnerWrapper = styled(Wrapper)`
  overflow: hidden;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 42px 17px 55px;
  }
`;

const SideBar = styled.div`
  width: 255px;
  font-size: 15px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin-bottom: 35px;
  }
`;

const Date = styled.p`
  color: ${colorsRGB.grey(0.6)};
`;

const CategoryContainer = styled(Container)`
  margin: 28px 0 0;
  flex-direction: column;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-flow: row wrap;
    margin: 15px -10px -10px 0;
  }
`;

const CategoryButton = styled.div <{
  current: boolean;
}>`
  margin: 0 0 15px 0;
  transition: opacity 0.3s ease;
  ${({ current }) => !current && `
    opacity: 0.5;
  `}
  &:hover{
    opacity: 1;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 10px 10px 0;
  }
`;

const ContentContainer = styled.div`
  width: calc(100% - 255px);
  > div{
    max-width: 712px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

const ShareContainer = styled(Container) <{
  inverted?: boolean;
}>`
  position: fixed;
  right: 0;
  top: 50vh;
  transform: translateY(-50%);
  z-index: 20;
  flex-flow: column wrap;
  padding: 17px 10px 17px 10px;
  background: ${colors.white};
  border-radius: 10px 0 0 10px;
  color: ${colors.green};
  transition: background 0.3s ease;

  ${({ inverted }) => inverted === true && `
    background: ${colors.green};
  `}

  button {
    margin-bottom: 15px;
    transition: opacity 0.3s ease;
    &:hover{
      opacity: 0.5;
    }
    *{
      transition: all 0.3s ease;
    }
    &:last-of-type{
      margin-bottom: 0;
    }
  }
`;

const StyledH1 = styled.h1<{isSmall: boolean;}>`
  &&{
    font-size: ${({isSmall}) => isSmall && `50px`};

    @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
      font-size: ${({isSmall}) => isSmall && `30px`};
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px){
      font-size: ${({isSmall}) => isSmall && `25px`};
    }
  }
`;

export default function BlogInnerPage({ data, location, pageContext }: Props) {
  const { nodes } = data.allSanityBlogInner;
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const { language, region, user } = useStore();
  const allCategories = useAllCategories(region.iban);
  const blogInnerLanguage = useBlogInnerLanguage(region.iban);
  const [scrolled, setScrolled] = useState(false);
  const pageSlug = usePageSlugs(region.iban);
  const blogUrl = setSlug(pageSlug.blog);
  const [backLink, setBackLink] = useState(blogUrl);
  const hasMounted = useHasMounted();

  const {
    password,
    seo,
    title,
    author,
    _createdAt,
    publishedDate,
    categories,
    image,
    imagedesktop,
    imageMobile,
    content,
    showNewsletter,
    featuredBlogs,
    showFullWidthContent,
    excerpt,
  } = nodes[0];
  const alternateRegions = data.altRegions.nodes.map(alt => alt.region);
  const heroMedia = [((isTabletWidth || isMobileWidth) && imageMobile) ? imageMobile : imagedesktop ?? image];
  const { GATSBY_BASE_URL } = process.env;
  const postUrl = GATSBY_BASE_URL + setSlug(location.pathname);
  const anchoredContainerRef = useRef<HTMLDivElement>(null);
  const stickyContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (anchoredContainerRef.current && stickyContainerRef.current) {
      const scrollDistance =
        document.documentElement.scrollTop +
        stickyContainerRef.current.getBoundingClientRect().top +
        stickyContainerRef.current.getBoundingClientRect().height;
      const anchorDistance =
        anchoredContainerRef.current.getBoundingClientRect().height;

      if (!anchorDistance) {
        setScrolled(false);
        return;
      }
      if (scrollDistance > anchorDistance) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
  };

  useEventListener("scroll", handleScroll);

  useEffect(() => {
    if (hasMounted) {
      if (location?.state?.prevPath != null) {
        setBackLink(location.state.prevPath);
      }
    }
  }, [hasMounted]);

  return (
    <div>
      <SEO
        seoData={seo}
        alternateRegions={alternateRegions}
        overwriteDescription={excerpt ?? toPlainText(content).split('. ')[0]}
        slug={pageContext?.pagePath}
      />
      <ProviderContainer password={password} isAdmin={user?.isAdmin}>
        <ShareContainer ref={stickyContainerRef} inverted={scrolled}>
          <LinkedinShareButton url={postUrl}>
            <LinkedInIcon inverted={scrolled} />
          </LinkedinShareButton>
          <FacebookShareButton url={postUrl}>
            <FacebookIcon inverted={scrolled} />
          </FacebookShareButton>
          <TwitterShareButton url={postUrl}>
            <TwitterIcon inverted={scrolled} />
          </TwitterShareButton >
        </ShareContainer>
        {heroMedia[0] &&
          <HeroTemplate refObj={anchoredContainerRef} media={heroMedia} heroMedia={heroMedia} hideExplore>
            <HeroContent className="rte" flexDirection="column">
              <Link to={backLink}>
                <SwipeIndicator text={language?.sliderBack ?? "Back"} isBack isRelative />
              </Link>
              {title &&
                <StyledH1 className="h2" isSmall={title.split(' ').length > 10}>{title}</StyledH1>
              }
              {author && <p>{language?.blogAuthor ?? "By"} {author}</p>}
            </HeroContent>
          </HeroTemplate>
        }
        <BlogInnerWrapper>
          <FlexGrid gridMargin="76px">
            <SideBar>
              <div className="rte">
                <Date>{publishedDate ?? _createdAt}</Date>
                {!isTabletWidth && !isMobileWidth && author && <p>{language?.blogAuthor ?? "By"} {author}</p>}
              </div>
              {allCategories.length > 1 &&
                <CategoryContainer>
                  {allCategories.map(category => {
                    if (category == null) return null;

                    const isCurrent = categories && categories.find(cat => cat?.slug?.current === category.slug?.current);

                    return (
                      <CategoryButton key={category.slug?.current} current={Boolean(isCurrent)}>
                        <Button
                          theme="green"
                          text={category.title as string}
                          linkTo={`${blogUrl}?filter=${category.slug?.current}`}
                          width="100%"
                        />
                      </CategoryButton>
                    )
                  })}
                </CategoryContainer>
              }
            </SideBar>
            <ContentContainer className="rte">
              <BlocksContent data={content} />
            </ContentContainer>
          </FlexGrid>
        </BlogInnerWrapper>

        {/* Newsletter Block Section */}
        {showNewsletter && blogInnerLanguage?.newsletterBlock &&
          <NewsletterBlock data={blogInnerLanguage.newsletterBlock} />
        }

        {/* Featured Blogs Block Section */}
        {featuredBlogs && blogInnerLanguage?.featuredBlogsBlock &&
          <FeaturedBlogsBlock data={blogInnerLanguage.featuredBlogsBlock} />
        }

        {/* Fullscreen Block Section */}
        {showFullWidthContent && blogInnerLanguage?.fullScreenContentBlock &&
          <FullScreenContentBlock data={blogInnerLanguage.fullScreenContentBlock} />
        }

      </ProviderContainer>
    </div>
  );
}

export const query = graphql`
  query BlogInnerQuery($region: String, $slug: String) {
    allSanityBlogInner(filter: { 
      region: { iban: { eq: $region } } 
      slug: { current: { eq: $slug } }
    }) {
      nodes {
        title
        password
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        author
        excerpt
        publishedDate(formatString: "MMM D YYYY")
        categories {
          ...sanityBlogCategories
        }
        _createdAt(formatString: "MMM D YYYY")
        image {
          ...sanityImageFullWidth
        }
        imageMobile {
          ...sanityImageFullWidth
        }
        imagedesktop {
          ...sanityImageFullWidth
        }
        content {
          _rawContent
        }
        showFullWidthContent
        showNewsletter
        featuredBlogs {
        ...sanityBlogInnerPreview
        }
      }
    }
    altRegions: allSanityAboutPage {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
